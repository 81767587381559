const DEFAULT_RETRY_TIME = 2_000;

export function isRetryable(error) {
  return Boolean(
    // must be a response error
    error.response &&
      // must be a rate limit error
      error.response.status === 429
  );
}

export function wait(error) {
  return new Promise((resolve) =>
    setTimeout(
      resolve,
      error?.response?.headers?.["retry-after"] * 1_000 || DEFAULT_RETRY_TIME
    )
  );
}

export function retry(axios, error) {
  if (!error.config) {
    throw error;
  } else {
    return axios(error.config);
  }
}

/**
 * Creates an interceptor for axios that retries on response code 429
 * unsing a header containing "retry-after" to get the delay.
 * @param axios - the axios client
 * @param options - optional overrides to `isRetryable`, `wait` and `retry` helpers
 * @returns the axios response function or an error
 * @example
 * ```ts
 * const client = axios.createClient()
 * client.interceptors.response.use(null, retryInterceptor(client))
 * ```
 */
export function retryInterceptor(axios, options = {}) {
  const {
    isRetryable: isRetryable2,
    wait: wait2,
    retry: retry2,
  } = { isRetryable, wait, retry, ...options };

  // eslint-disable-next-line func-names
  return async function (error) {
    if (isRetryable2(error)) {
      await wait2(error);
      return retry2(axios, error);
    }

    throw error;
  };
}
