export const initialState = {
  assets: [],
  assetsWithoutSeries: [],
  baseDate: null,
  lastUpdateDate: null,
  selectedPeriod: "OTIMO", // CUSTOMIZATION
  initialApplication: 100,
  selectedTab: 1,
  selectedBenchmarksIndexes: [0, 1, 2], // CUSTOMIZATION
  correlationData: {},
  benchmarkAssets: [],
  isFetchingAssetsSeries: false,
  periodRange: {
    initialDate: null,
    finalDate: null,
  },
  userHasInteractedWithPeriod: true, // CUSTOMIZATION
};

export * from "./reducer";
export * from "./selectors";
