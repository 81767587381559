export const API_URL = 'https://api.quantumaxis.com.br/widgets/v1/'

export const API_SUBSCRIPTION_KEY = '17c6ce3853374191ad087e2186ed306c'

export const HOME_TITLE = 'Cartesius,'

export const THEME = 'cartesius'

export const SHARE_URL = 'https://cartesius.quantumfinance.com.br/#';
export const APPLICATION_INSIGHTS_CONNECTION_STRING = 'InstrumentationKey=24413b36-11c2-436f-8a26-0505ec7c92a4;IngestionEndpoint=https://brazilsouth-0.in.applicationinsights.azure.com/'

export const REDUX_PANE = false;
export const REDUX_LOGGER = false;
