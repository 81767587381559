import axios from "axios";
import { API_SUBSCRIPTION_KEY, API_URL } from "environment";
import { stringify as qsStringify } from "query-string";
import { retryInterceptor } from "utils";
import { initialState } from "../state/index";

const api = axios.create({
  baseURL: API_URL,
  headers: { "x-subscriptionkey": API_SUBSCRIPTION_KEY },
});

api.interceptors.response.use(undefined, retryInterceptor(axios)(api));

export const createQuantumApi = () => {
  return {
    ...api,
    fetchPeriods: () =>
      api
        .get("period")
        .then((res) => res.data)
        .catch(console.log),

    fetchPeriodRange: (period, assets, baseDate) => {
      const isOptimumPeriod = period === "OTIMO";
      const qsOptions = {
        arrayFormat: "separator",
        arrayFormatSeparator: "|",
        skipEmptyString: true,
      };

      return api
        .get(
          `period/${period}?${
            isOptimumPeriod
              ? qsStringify(
                  {
                    assetType: assets.map((asset) => asset.assetType),
                    identifier: assets.map((asset) => asset.identifier),
                  },
                  qsOptions
                )
              : qsStringify({ baseDate: baseDate || "" }, qsOptions)
          }`
        )
        .then((res) => res.data)
        .catch((err) => {
          if (err.response.status === 404) {
            return {
              initialDate: null,
              finalDate: null,
              assetsWithNoSeriesIntersection: [],
            };
          }
          throw err;
        });
    },

    fetchDefaultPeriod: (assets) =>
      api
        .post(
          "/period/default_FORCE_ERROR", // CUSTOMIZATION: FORCE USAGE OF DEFAULT ON STATE
          JSON.stringify(
            (assets ?? []).map(({ assetType, identifier }) => ({
              assetType,
              identifier,
            }))
          )
        )
        .then(({ data }) => data)
        .catch(() => ({ period: initialState.selectedPeriod })),

    fetchOptimumPeriod: (assets) =>
      api.get(
        `period/optimum?${qsStringify(
          {
            assetType: assets.map((asset) => asset.assetType),
            identifier: assets.map((asset) => asset.identifier),
          },
          { arrayFormat: "separator", arrayFormatSeparator: "|" }
        )}`
      ),

    fetchRentabilitySeries: (
      assetType,
      assetIdentifier,
      periodRange,
      initialApplication
    ) =>
      api
        .get(
          `calculation/${assetType}/${assetIdentifier}/return/series?${qsStringify(
            {
              initialApplication: initialApplication,
              initialDate: periodRange.initialDate,
              finalDate: periodRange.finalDate,
            }
          )}`
        )
        .then((res) => res)
        .catch(console.log),

    fetchReturnSeries: (assetType, assetIdentifier, periodRange) =>
      api
        .get(
          `calculation/${assetType}/${assetIdentifier}/return/cumulative/series?${qsStringify(
            {
              initialDate: periodRange.initialDate,
              finalDate: periodRange.finalDate,
            }
          )}`
        )
        .then((res) => res)
        .catch(console.log),

    fetchDrawdownSeries: (assetType, assetIdentifier, periodRange) =>
      api
        .get(
          `calculation/${assetType}/${assetIdentifier}/drawdown/series?${qsStringify(
            {
              initialDate: periodRange.initialDate,
              finalDate: periodRange.finalDate,
            }
          )}`
        )
        .then((res) => res)
        .catch(console.log),

    fetchReturnHistory: (assetType, assetIndentifier) =>
      api
        .get(`calculation/${assetType}/${assetIndentifier}/return/history`)
        .then((res) => res)
        .catch(console.log),

    fetchIndicator: (
      assetType,
      assetIdentifier,
      measure,
      periodRange,
      initialApplication = false
    ) =>
      api
        .get(
          `calculation/${assetType}/${assetIdentifier}/${measure}?${qsStringify(
            {
              initialApplication: initialApplication || "",
              initialDate: periodRange.initialDate,
              finalDate: periodRange.finalDate,
            }
          )}`
        )
        .then((res) => res)
        .catch(
          () =>
            new Promise({
              finalDate: periodRange.finalDate,
              value: null,
            })
        ),

    fetchBenchmarksAssets: () =>
      api
        .get("asset/indices")
        .then((res) => res)
        .catch(console.log),

    fetchCorrelationData: (periodRange, assets) =>
      api
        .get(
          `calculation/correlation?${qsStringify(
            {
              assetType: assets.map((asset) => asset.assetType),
              identifier: assets.map((asset) => asset.identifier),
              initialDate: periodRange.initialDate,
              finalDate: periodRange.finalDate,
            },
            { arrayFormat: "separator", arrayFormatSeparator: "|" }
          )}`
        )
        .then((res) => res.data)
        .catch(console.log),

    fetchAssetsNames: (assetType, assetIdentifier) =>
      api
        .get(
          `calculation/${String(assetType)}/${String(
            assetIdentifier
          )}/NOME/?${qsStringify({
            initialDate: "13-12-2020",
            finalDate: "13-12-2020",
          })}`
        )
        .then((res) => String(res.data.value))
        .catch(console.log),

    fetchLastUpdateDate: () =>
      api
        .get("last_update_date")
        .then((res) => res.data)
        .catch(console.log),
  };
};
