import defaultTheme from "./clients/defaultTheme";
import ouiTheme from "./clients/ouiTheme";
import cartesius from "./clients/cartesiusTheme";

const Themes = {
  default: defaultTheme,
  "oui-investimentos": ouiTheme,
  cartesius: cartesius,
};

export default Themes;
