import styled from "styled-components";
import { Div, Img } from "components";
import { Grid, Typography } from "@mui/material";
import { rgba } from "polished";
import QrCodeIcon from "@mui/icons-material/QrCode";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";

export const Wrapper = styled(Div)`
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  padding: 0px;
  padding-top: 24px;

  /* @media (max-width: 920px) {
    padding: 0px;
  } */
`;

export const WrapperGrid = styled(Grid)`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

export const FirstRow = styled(Grid)`
  && {
    display: flex;
    justify-content: space-between;
  }
`;

export const SecondRow = styled(Grid)`
  && {
    display: flex;
  }
`;

export const Title = styled(Typography)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-size: 24px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.gray[5]};

    /* @media (max-width: 920px) {
      color: ${({ theme }) => theme.textColors.primary.color};
    } */
  }
`;

export const Message = styled(Typography)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-size: 16px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.gray[5]};

    /* @media (max-width: 920px) {
      color: ${({ theme }) => theme.textColors.primary.color};
    } */
  }
`;

export const NavLeft = styled(Grid)`
  && {
    display: flex;
    flex-direction: column;
  }
`;

export const NavRight = styled.ul`
  display: flex;
  list-style-type: none;
  border: 1px solid ${({ theme }) => theme.colors.gray[theme.borders[0]]};

  /* @media (max-width: 920px) {
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 8px;
    padding-bottom: 12px;
    border: none;
    overflow-x: auto;

    /* CSS para forçar scroll a apararecer sempre no modo mobile */
    /* ::-webkit-scrollbar {
      -webkit-appearance: none;
    }
    ::-webkit-scrollbar:horizontal {
      height: 4px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 3px;
      border: 1px solid #ffffff;
    }
    ::-webkit-scrollbar-track {
      border-radius: 3px;
      background-color: #ffffff;
    } */
  } */
`;

export const StyledImg = styled(Img)`
  margin-right: 8px;
  margin-left: 0;
`;

export const STypography = styled(Typography)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-size: 14px;
    font-weight: 400;
    margin-left: 10px;
  }
`;

export const SBold = styled(Typography)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-size: 14px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.gray[4]};
    margin-left: 10px;
  }
`;

export const SContainer = styled(Grid)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    min-width: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #0a1916;
    cursor: pointer;
    margin-left: 8px;
    border-radius: 4px;
    padding: 0 12px;
    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: #28285c;
      border-color: #28285c;
      color: #f7a526;
      opacity: 1;
    }

    /* @media (max-width: 920px) {
    border: 1px solid white;
    border-radius: 2px;
    & + & {
      margin-left: 12px;
    }
    color: ${({ theme }) =>
      rgba(
        theme.colors[theme.textColors.primary.color][
          theme.textColors.primary.intensity
        ],
        0.8
      )}
    min-width: fit-content;
  } */
  }
`;

export const QRCodeIcon = styled(QrCodeIcon)`
  && {
  }
`;

export const CopyContentIcon = styled(ContentCopyIcon)`
  && {
  }
`;

export const BaseDateCalendarIcon = styled(CalendarTodayOutlinedIcon)`
  && {
    color: ${({ theme }) => theme.colors.gray[4]};
  }
`;
