import React from "react";
import { Route, Switch } from "react-router-dom";
import { HomePage, GraphicPage } from "components";

const Routes = () => {
  return (
    <Switch id="Routes">
      {/* <Route exact path="/" component={HomePage} /> */}
      <Route exact path="/:urlData" component={GraphicPage}></Route>
      <Route exact path="/" component={GraphicPage}></Route>
    </Switch>
  );
};

export default Routes;
