import React, { useState, useContext, useEffect } from "react";
import { contains, difference, drop, head, take } from "ramda";
import { LanguageContext } from "../../i18n";
import { createQuantumApi } from "../../api";
import AssetsContext from "hooks/AssetsProvider/AssetsContext";
import Utils from "utils/utils";
import {
  useSelectAssets,
  useSelectAssetsWithoutSeries,
  useSelectBenchmarksIndexes,
  useSelectInicialApplication,
  useSelectPeriod,
  useBaseDate,
  useBenchmarkAssets,
  useCorrelationData,
  useFetchingAssetsSeries,
  useLastUpdateDate,
  usePeriodRange,
  useUserHasInteractedWithPeriod,
} from "../../state";

import {
  ASSETS_COLOR_PALETTE,
  BENCHMARKS_COLOR_PALETTE,
  PERIOD_OPTIMAL,
} from "../../models/constants";

const quantumApi = createQuantumApi();

const POPULATE_PROMISE_RETURN_HISTORY_INDEX = 0;
const POPULATE_PROMISE_RENTABILITY_SERIES_INDEX = 1;
const POPULATE_PROMISE_RETURN_SERIES_INDEX = 2;
const POPULATE_PROMISE_INDICATORS_RENTABILITY_INDEX = 3;
const POPULATE_PROMISE_INDICATORS_RETURN_INDEX = 4;
const POPULATE_PROMISE_INDICATORS_DRAWDOWN_INDEX = 5;
const POPULATE_PROMISE_DRADOWN_SERIES_INDEX = 6;
const POPULATE_PROMISE_ASSET_NAME_INDEX = 7;

const UPDATE_PROMISE_RENTABILITY_SERIES_INDEX = 0;
const UPDATE_PROMISE_RETURN_SERIES_INDEX = 1;
const UPDATE_PROMISE_INDICATORS_RENTABILITY_INDEX = 2;
const UPDATE_PROMISE_INDICATORS_RETURN_INDEX = 3;
const UPDATE_PROMISE_INDICATORS_DRAWDOWN_INDEX = 4;
const UPDATE_PROMISE_DRADOWN_SERIES_INDEX = 5;

const AssetsProvider = ({ children }) => {
  // const [assets, setAssets] = useState([]);
  const [assets, setAssets] = useSelectAssets();
  const [assetsWithoutSeries, setAssetsWithoutSeries] =
    useSelectAssetsWithoutSeries();

  // const [benchmarkAssets, setBenchmarkAssets] = useState([]);
  const [baseDate] = useBaseDate();
  const [benchmarkAssets, setBenchmarkAssets] = useBenchmarkAssets();
  const [correlationData, setCorrelationData] = useCorrelationData();
  const [isFetchingAssetsSeries, setFetchingAssetsSeries] =
    useFetchingAssetsSeries();
  const [lastUpdateDate, setLastUpdateDate] = useLastUpdateDate();
  const [periodRange, setPeriodRange] = usePeriodRange();
  const [userHasInteractedWithPeriod] = useUserHasInteractedWithPeriod();

  // const [selectedBenchmarksIndexes, setSelectedBenchmarksIndexes] = useState(
  //   []
  // );
  const [selectedBenchmarksIndexes, setSelectedBenchmarksIndexes] =
    useSelectBenchmarksIndexes();

  const [initialApplication] = useSelectInicialApplication();
  const [selectedPeriod, setSelectedPeriod] = useSelectPeriod();

  const {
    shortMonthNameForDate,
    yearForDate,
    date2DigitsFormatter,
    formatCurrency,
    formatNumber,
    formatPercent,
    formatPeriod,
  } = Utils();
  const { t } = useContext(LanguageContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isLockedUpdateOnChangePeriod, lockUpdateOnChangePeriod] =
    useState(false);

  const setSelectedPeriodLockingUpdate = (period) => {
    lockUpdateOnChangePeriod(true);
    // setSelectedPeriod(period); // CUSTOMIZATION: THIS WAS CAUSING OPTIMAL PERIOD TO BE UNSELECTED
    setTimeout(() => lockUpdateOnChangePeriod(false), 100);
  };

  const populateAsset = async (
    asset,
    periodRange,
    initialApplication,
    calculateRentabilitySeries
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const assetFoundIndex = findAssetIndexByIdentifierAndType(
          asset.identifier,
          asset.assetType
        );

        if (assetFoundIndex === -1) {
          const benchmarkIndex = findBenchmarkAsset(
            asset.identifier,
            asset.assetType
          );

          //add benchmark to selected benchmark indexes
          if (benchmarkIndex !== -1) {
            setSelectedBenchmarksIndexes([
              ...selectedBenchmarksIndexes,
              benchmarkIndex,
            ]);
          }

          const assetsPromises = [
            quantumApi.fetchReturnHistory(asset.assetType, asset.identifier),
            calculateRentabilitySeries
              ? quantumApi.fetchRentabilitySeries(
                  asset.assetType,
                  asset.identifier,
                  periodRange,
                  initialApplication
                )
              : Promise.resolve(),
            quantumApi.fetchReturnSeries(
              asset.assetType,
              asset.identifier,
              periodRange
            ),
            processIndicatorsRentability(
              asset.assetType,
              asset.identifier,
              periodRange,
              initialApplication
            ),
            processIndicatorsReturn(
              asset.assetType,
              asset.identifier,
              periodRange
            ),
            processIndicatorsDrawdown(
              asset.assetType,
              asset.identifier,
              periodRange
            ),
            quantumApi.fetchDrawdownSeries(
              asset.assetType,
              asset.identifier,
              periodRange
            ),
            processAssetName(asset),
          ];

          const resolvedAssetsPromises = await Promise.allSettled(
            assetsPromises
          );

          // add returnHistoryData
          if (
            resolvedAssetsPromises[POPULATE_PROMISE_RETURN_HISTORY_INDEX]
              .status === "fulfilled"
          ) {
            const processedHistoryData = processReturnHistoryData(
              resolvedAssetsPromises[POPULATE_PROMISE_RETURN_HISTORY_INDEX]
                .value.data
            );
            asset.returnHistoryData = processedHistoryData;
          }

          // add rentability series
          if (
            resolvedAssetsPromises[POPULATE_PROMISE_RENTABILITY_SERIES_INDEX]
              .status === "fulfilled" &&
            calculateRentabilitySeries
          ) {
            const processedRentabilitySeries = processSeries(
              resolvedAssetsPromises[POPULATE_PROMISE_RENTABILITY_SERIES_INDEX]
                .value.data
            );
            asset.rentabilitySeries = processedRentabilitySeries;
          }

          // add return series
          if (
            resolvedAssetsPromises[POPULATE_PROMISE_RETURN_SERIES_INDEX]
              .status === "fulfilled"
          ) {
            const processedReturnSeries = processSeries(
              resolvedAssetsPromises[POPULATE_PROMISE_RETURN_SERIES_INDEX].value
                .data,
              true
            );
            asset.returnSeries = processedReturnSeries;
          }

          // add indicators data
          if (
            resolvedAssetsPromises[
              POPULATE_PROMISE_INDICATORS_RENTABILITY_INDEX
            ].status === "fulfilled"
          ) {
            asset.indicatorsRentabilityData =
              resolvedAssetsPromises[
                POPULATE_PROMISE_INDICATORS_RENTABILITY_INDEX
              ].value;
          }

          // add indicators data
          if (
            resolvedAssetsPromises[POPULATE_PROMISE_INDICATORS_RETURN_INDEX]
              .status === "fulfilled"
          ) {
            asset.indicatorsReturnData =
              resolvedAssetsPromises[
                POPULATE_PROMISE_INDICATORS_RETURN_INDEX
              ].value;
          }

          // add indicators data
          if (
            resolvedAssetsPromises[POPULATE_PROMISE_INDICATORS_DRAWDOWN_INDEX]
              .status === "fulfilled"
          ) {
            asset.indicatorsDrawdownData =
              resolvedAssetsPromises[
                POPULATE_PROMISE_INDICATORS_DRAWDOWN_INDEX
              ].value;
          }

          // add return series
          if (
            resolvedAssetsPromises[POPULATE_PROMISE_DRADOWN_SERIES_INDEX]
              .status === "fulfilled"
          ) {
            const processedDrawdownSeries = processSeries(
              resolvedAssetsPromises[POPULATE_PROMISE_DRADOWN_SERIES_INDEX]
                .value.data,
              true
            );
            asset.drawdownSeries = processedDrawdownSeries;
          }

          // Add asset name. Fetch asset name if not available.
          if (
            resolvedAssetsPromises[POPULATE_PROMISE_ASSET_NAME_INDEX].status ===
            "fulfilled"
          ) {
            asset.label =
              resolvedAssetsPromises[POPULATE_PROMISE_ASSET_NAME_INDEX].value;
          }

          resolve(asset);
        }
        resolve(undefined);
      } catch (err) {
        reject(err);
      }
    });
  };

  const getAssetColor = (assetsParam, assetType) => {
    const colorPalette =
      assetType === "INDICE" ? BENCHMARKS_COLOR_PALETTE : ASSETS_COLOR_PALETTE;

    const assets =
      assetType === "INDICE"
        ? assetsParam.filter(({ assetType }) => assetType === "INDICE")
        : assetsParam.filter(({ assetType }) => assetType !== "INDICE");

    const colors = [
      ...drop(assets.length, colorPalette),
      ...take(assets.length, colorPalette),
    ];

    const usedColors = assets
      .map(({ color }) => color)
      .filter((color) => contains(color, colors));

    const color =
      usedColors.length < colors.length
        ? head(difference(colors, usedColors))
        : colors[assets.length % colorPalette.length];

    return color;
  };

  const fetchPeriodRange = async (period, assets, baseDate) => {
    try {
      const { initialDate, finalDate, assetsWithNoSeriesIntersection } =
        await quantumApi.fetchPeriodRange(period, assets, baseDate);
      setPeriodRange({ initialDate, finalDate });

      return [
        {
          initialDate,
          finalDate,
        },
        assetsWithNoSeriesIntersection || [],
      ];
    } catch (err) {
      console.log("[Erro ao obter período]", err);
    }
  };

  const addAsset = async (asset, period, initialApplication) => {
    setIsLoading(true);
    setFetchingAssetsSeries(true);
    try {
      const assetFoundIndex = findAssetIndexByIdentifierAndType(
        asset.identifier,
        asset.assetType
      );

      if (assetFoundIndex === -1) {
        const color = getAssetColor(assets, asset.assetType);

        const otherAssets = assets.filter(
          ({ assetType }) => assetType !== "INDICE"
        );
        const benchmarks = assets.filter(
          ({ assetType }) => assetType === "INDICE"
        );

        const isAddingFirstAsset = otherAssets.length === 0;

        if (isAddingFirstAsset) {
          const { period: defaultPeriod } = await quantumApi.fetchDefaultPeriod(
            [...assets, asset]
          );

          period = defaultPeriod;
          setSelectedPeriodLockingUpdate(period);
        }

        const previousPeriodRange = periodRange;

        const [originalPeriodRange, assetsWithoutSeries] =
          await fetchPeriodRange(period, [...assets, asset]);

        if (period === PERIOD_OPTIMAL) {
          const benchmarkIndex = findBenchmarkAsset(
            asset.identifier,
            asset.assetType
          );

          //add benchmark to selected benchmark indexes
          if (benchmarkIndex !== -1) {
            setSelectedBenchmarksIndexes([
              ...selectedBenchmarksIndexes,
              benchmarkIndex,
            ]);
          }

          const { data } = await quantumApi.fetchReturnHistory(
            asset.assetType,
            asset.identifier
          );
          const returnHistoryData = processReturnHistoryData(data);

          const newAsset = { ...asset, returnHistoryData, color };

          const newAssets =
            newAsset.assetType === "INDICE"
              ? [...otherAssets, ...benchmarks, newAsset]
              : [...otherAssets, newAsset, ...benchmarks];

          /* Caso seja o período ótimo, precisamos recalcular a série dos outros
          ativos.
          O cálculo do restante da série do novo ativo é feito nesse método para
          evitar o reprocessamento.
          */
          await updateAssetsData(
            newAssets,
            originalPeriodRange,
            initialApplication,
            false
          );
          setAssetsWithoutSeries(assetsWithoutSeries);
        } else {
          const newAssetRentabilitySeries = await processRentabilitySeries(
            asset.assetType,
            asset.identifier,
            originalPeriodRange,
            initialApplication
          );

          let recalculateData = false;
          let periodRange = originalPeriodRange;

          if (
            previousPeriodRange?.finalDate !== lastUpdateDate ||
            newAssetRentabilitySeries.length === 0
          ) {
            try {
              const [optimalPeriodRange] = await fetchPeriodRange(
                PERIOD_OPTIMAL,
                [...assets, asset]
              );

              if (newAssetRentabilitySeries.length === 0) {
                periodRange = optimalPeriodRange;
                period = PERIOD_OPTIMAL;
                setSelectedPeriodLockingUpdate(period);
              } else {
                // Fluxo de ativos com cotas atrasadas
                const [recalculatedPeriodRange] = await fetchPeriodRange(
                  period,
                  [],
                  optimalPeriodRange.finalDate
                );
                periodRange = recalculatedPeriodRange;
              }

              recalculateData = true;
            } catch (err) {
              console.log("Sem interseção. Período não será alterado.");
            }
          }

          const populatedAsset = await populateAsset(
            asset,
            periodRange,
            initialApplication,
            recalculateData
          );

          if (populatedAsset) {
            const newAsset = {
              ...populatedAsset,
              color,
              rentabilitySeries: recalculateData
                ? populatedAsset.rentabilitySeries
                : newAssetRentabilitySeries,
            };

            const newAssets =
              newAsset.assetType === "INDICE"
                ? [...otherAssets, ...benchmarks, newAsset]
                : [...otherAssets, newAsset, ...benchmarks];

            const correlationData = await quantumApi.fetchCorrelationData(
              periodRange,
              newAssets
            );
            setCorrelationData(correlationData);
            setAssets(newAssets);

            if (recalculateData) {
              await updateAssetsData(
                newAssets,
                periodRange,
                initialApplication,
                true
              );
            } else {
              setAssetsWithoutSeries(
                newAssets.filter(
                  ({ rentabilitySeries }) => rentabilitySeries.length === 0
                )
              );
            }
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
    setFetchingAssetsSeries(false);
  };

  const addAssetsBatch = async (
    assetsToBatch,
    period,
    initialApplication,
    changePeriodOnLoad
  ) => {
    try {
      setFetchingAssetsSeries(true);

      let [periodRange] = await fetchPeriodRange(period, [
        ...assets,
        ...assetsToBatch,
      ]);

      const newAssets = [...assets];
      if (assetsToBatch.length > 0) {
        // Calcula a série de todos os ativos para o período desejado
        const assetsSeries = [];
        for (let i = 0; i < assetsToBatch.length; i++) {
          const { assetType, identifier } = assetsToBatch[i];

          const rentabilitySeries = await processRentabilitySeries(
            assetType,
            identifier,
            periodRange,
            initialApplication
          );

          assetsSeries.push(rentabilitySeries);
        }

        const assetsWithoutSeries = assetsSeries.filter(
          (series) => series.length === 0
        );

        let recalculateData = false;

        // Verifica se existe algum ativo sem série para o período escolhido.
        // Caso tenha um ativo sem série e seja durante o carregamento da tela,
        // utilizar o período Ótimo, caso contrário, voltar a database e recalcular período.
        if (assetsWithoutSeries.length) {
          try {
            const [optimalPeriodRange, assetsWithoutSeries] =
              await fetchPeriodRange(PERIOD_OPTIMAL, [
                ...assets,
                ...assetsToBatch,
              ]);

            if (changePeriodOnLoad && period !== PERIOD_OPTIMAL) {
              periodRange = optimalPeriodRange;
              setAssetsWithoutSeries(assetsWithoutSeries);
              setSelectedPeriod(PERIOD_OPTIMAL);
            } else {
              const [recalculatedPeriodRange] = await fetchPeriodRange(
                period,
                [],
                optimalPeriodRange.finalDate
              );
              periodRange = recalculatedPeriodRange;
            }

            recalculateData = true;
          } catch (err) {
            console.log("Sem interseção. Período não será alterado.");
          }
        }

        for (let i = 0; i < assetsToBatch.length; i++) {
          const newAsset = await populateAsset(
            assetsToBatch[i],
            periodRange,
            initialApplication,
            recalculateData
          );

          newAssets.push({
            ...newAsset,
            rentabilitySeries: recalculateData
              ? newAsset.rentabilitySeries
              : assetsSeries[i],
            color: newAsset.color
              ? newAsset.color
              : getAssetColor(newAssets, newAsset.assetType),
          });
        }

        const otherAssets = newAssets.filter(
          ({ assetType }) => assetType !== "INDICE"
        );
        const benchmarks = newAssets.filter(
          ({ assetType }) => assetType === "INDICE"
        );

        // Regra de negócio: Benchmarks devem ser adicionados após os demais
        // tipos de ativos
        const newOrderedAssets = [...otherAssets, ...benchmarks];
        setAssets(newOrderedAssets);

        if (newOrderedAssets.length > 0) {
          const correlationData = await quantumApi.fetchCorrelationData(
            periodRange,
            newOrderedAssets
          );
          setCorrelationData(correlationData);
        }

        if (!changePeriodOnLoad) {
          // Caso não seja possível trocar para o período ótimo, mostrar modal
          // de ativos sem série
          setAssetsWithoutSeries(
            newOrderedAssets.filter(
              ({ rentabilitySeries }) => rentabilitySeries.length === 0
            )
          );
        }

        if (recalculateData || period === PERIOD_OPTIMAL) {
          await updateAssetsData(
            newOrderedAssets,
            periodRange,
            initialApplication,
            true
          );
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setFetchingAssetsSeries(false);
    }
  };

  const updateAssetsData = async (
    assetsArr,
    periodRange,
    initialApplication,
    verifyAssetsSeries
  ) => {
    try {
      setIsLoading(true);
      const clonedAssets = [...assetsArr];

      for (let i = 0; i < clonedAssets.length; i += 1) {
        const assetsPromises = [
          processRentabilitySeries(
            clonedAssets[i].assetType,
            clonedAssets[i].identifier,
            periodRange,
            initialApplication
          ),
          quantumApi.fetchReturnSeries(
            clonedAssets[i].assetType,
            clonedAssets[i].identifier,
            periodRange
          ),
          processIndicatorsRentability(
            clonedAssets[i].assetType,
            clonedAssets[i].identifier,
            periodRange,
            initialApplication
          ),
          processIndicatorsReturn(
            clonedAssets[i].assetType,
            clonedAssets[i].identifier,
            periodRange
          ),
          processIndicatorsDrawdown(
            clonedAssets[i].assetType,
            clonedAssets[i].identifier,
            periodRange
          ),
          quantumApi.fetchDrawdownSeries(
            clonedAssets[i].assetType,
            clonedAssets[i].identifier,
            periodRange
          ),
        ];
        const assetsPromisesResolved = await Promise.allSettled(assetsPromises);

        //update rentability series
        if (
          assetsPromisesResolved[UPDATE_PROMISE_RENTABILITY_SERIES_INDEX]
            .status === "fulfilled"
        ) {
          clonedAssets[i].rentabilitySeries =
            assetsPromisesResolved[
              UPDATE_PROMISE_RENTABILITY_SERIES_INDEX
            ].value;
        }

        //update return series
        if (
          assetsPromisesResolved[UPDATE_PROMISE_RETURN_SERIES_INDEX].status ===
          "fulfilled"
        ) {
          const processedReturnSeries = processSeries(
            assetsPromisesResolved[UPDATE_PROMISE_RETURN_SERIES_INDEX].value
              .data,
            true
          );
          clonedAssets[i].returnSeries = processedReturnSeries;
        }

        //update indicators data
        if (
          assetsPromisesResolved[UPDATE_PROMISE_INDICATORS_RENTABILITY_INDEX]
            .status === "fulfilled"
        ) {
          clonedAssets[i].indicatorsRentabilityData =
            assetsPromisesResolved[
              UPDATE_PROMISE_INDICATORS_RENTABILITY_INDEX
            ].value;
        }
        //update indicators return data
        if (
          assetsPromisesResolved[UPDATE_PROMISE_INDICATORS_RETURN_INDEX]
            .status === "fulfilled"
        ) {
          clonedAssets[i].indicatorsReturnData =
            assetsPromisesResolved[
              UPDATE_PROMISE_INDICATORS_RETURN_INDEX
            ].value;
        }

        //update indicators drawdown data
        if (
          assetsPromisesResolved[UPDATE_PROMISE_INDICATORS_DRAWDOWN_INDEX]
            .status === "fulfilled"
        ) {
          clonedAssets[i].indicatorsDrawdownData =
            assetsPromisesResolved[
              UPDATE_PROMISE_INDICATORS_DRAWDOWN_INDEX
            ].value;
        }

        //update drawdown series
        if (
          assetsPromisesResolved[UPDATE_PROMISE_DRADOWN_SERIES_INDEX].status ===
          "fulfilled"
        ) {
          const processedDrawdownSeries = processSeries(
            assetsPromisesResolved[UPDATE_PROMISE_DRADOWN_SERIES_INDEX].value
              .data,
            true
          );
          clonedAssets[i].drawdownSeries = processedDrawdownSeries;
        }
      }
      if (clonedAssets.length > 0) {
        const correlationData = await quantumApi.fetchCorrelationData(
          periodRange,
          clonedAssets
        );
        setCorrelationData(correlationData);
      }
      setAssets(clonedAssets);

      if (verifyAssetsSeries) {
        setAssetsWithoutSeries(
          clonedAssets.filter(
            ({ rentabilitySeries }) => rentabilitySeries.length === 0
          )
        );
      }
    } catch (err) {
      console.log("[Erro ao atualizar os dados dos ativos]", err);
    } finally {
      setIsLoading(false);
    }
  };

  const updateAssetsDataForPeriodChange = async (
    selectedPeriod,
    assets,
    periodRangeParam
  ) => {
    let periodRange =
      periodRangeParam ??
      (await quantumApi.fetchPeriodRange(selectedPeriod, assets));

    const assetsSeries = [];

    // TODO: Podemos otimizar essa parte
    for (let i = 0; i < assets.length; i++) {
      const { assetType, identifier } = assets[i];

      const rentabilitySeries = await processRentabilitySeries(
        assetType,
        identifier,
        periodRange,
        initialApplication
      );

      assetsSeries.push(rentabilitySeries);
    }

    const assetsWithoutSeries = assetsSeries.filter(
      (series) => series.length === 0
    );

    let recalculateRentabilitySeries = false;

    if (assetsWithoutSeries.length) {
      try {
        const [optimalPeriodRange] = await fetchPeriodRange(
          PERIOD_OPTIMAL,
          assets
        );
        const [recalculatedPeriodRange] = await fetchPeriodRange(
          selectedPeriod,
          [],
          optimalPeriodRange.finalDate
        );
        periodRange = recalculatedPeriodRange;
        recalculateRentabilitySeries = true;
      } catch (err) {
        console.log("Sem interseção. Período não será alterado.");
      }
    }

    // TODO: Podemos otimizar essa parte, evitando o recalculo da série
    await updateAssetsData(assets, periodRange, initialApplication, true);
    setPeriodRange(periodRange);
  };

  const updateWhenChangesInitialApplication = async (initialApplication) => {
    setIsLoading(true);
    try {
      await updateAssetsData(assets, periodRange, initialApplication, true);
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  const updateWhenChangesPeriod = async (period) => {
    setIsLoading(true);
    try {
      if (isLockedUpdateOnChangePeriod) {
        return;
      }

      const originalPeriodRange = await quantumApi.fetchPeriodRange(
        period,
        assets
      );

      if (period === PERIOD_OPTIMAL) {
        await updateAssetsData(
          assets,
          originalPeriodRange,
          initialApplication,
          true
        );
        setPeriodRange(originalPeriodRange);
      } else {
        await updateAssetsDataForPeriodChange(
          period,
          assets,
          originalPeriodRange
        );
      }
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  const findBenchmarkAsset = (assetIdentifier, assetType) => {
    let benchmarkAssetIndexFounded = -1;
    for (let i = 0; i < benchmarkAssets.length; i++) {
      if (
        benchmarkAssets[i].assetType === assetType &&
        benchmarkAssets[i].identifier === assetIdentifier
      ) {
        benchmarkAssetIndexFounded = i;
        break;
      }
    }
    return benchmarkAssetIndexFounded;
  };

  const formatValue = (value, format) => {
    switch (format) {
      case "CURRENCY":
        return formatCurrency(value);

      case "PERCENTAGE":
        return formatPercent(value);

      case "DATE":
        let date = new Date(value);
        return date2DigitsFormatter.format(
          date && date.setHours(date.getHours() + 3)
        );
      case "NUMBER":
      default:
        return formatNumber(value);
    }
  };

  const isAssetFund = (assetType) =>
    ["FI", "FII", "EUROPA"].includes(assetType);

  const processAssetName = async ({ assetType, identifier, label }) => {
    if (label) {
      return label;
    }

    return await quantumApi.fetchAssetsNames(assetType, identifier);
  };

  const processIndicatorsRentability = async (
    assetType,
    assetIdentifier,
    period,
    initialApplication
  ) => {
    const measures = [
      "RENTABILIDADE",
      "VOLATILIDADE",
      "SHARPE",
      "TAXA_DE_ADMINISTRACAO",
      "PATRIMONIO_LIQUIDO",
      "PATRIMONIO_LIQUIDO_MEDIO_SEIS_MESES", // TODO HERE
    ];
    let indicatorsProcessed = {};
    let indicatorsPromises = [];

    for (let i = 0; i < measures.length; i += 1) {
      indicatorsPromises = [
        ...indicatorsPromises,
        quantumApi.fetchIndicator(
          assetType,
          assetIdentifier,
          measures[i],
          period,
          initialApplication
        ),
      ];
    }

    const resolvedIndicators = await Promise.allSettled(indicatorsPromises);

    for (let i = 0; i < measures.length; i += 1) {
      if (resolvedIndicators[i].status === "fulfilled") {
        indicatorsProcessed[t[`measure:${measures[i]}`]] =
          !isAssetFund(assetType) &&
          (measures[i] === "TAXA_DE_ADMINISTRACAO" ||
            measures[i] === "PATRIMONIO_LIQUIDO")
            ? {
                value: "-",
                period: formatPeriod(
                  resolvedIndicators[i].value.data.finalDate,
                  resolvedIndicators[i].value.data.initialDate
                ),
              }
            : {
                value:
                  resolvedIndicators[i].value.data.value !== null
                    ? formatValue(
                        resolvedIndicators[i].value.data.value,
                        resolvedIndicators[i].value.data.format
                      )
                    : "-",
                period: formatPeriod(
                  resolvedIndicators[i].value.data.finalDate,
                  resolvedIndicators[i].value.data.initialDate
                ),
              };
      }
    }

    return indicatorsProcessed;
  };

  const processIndicatorsReturn = async (
    assetType,
    assetIdentifier,
    period
  ) => {
    const measures = ["RETORNO", "RETORNO_UM_ANO", "RETORNO_YTD"];
    let indicatorsProcessed = {};
    let indicatorsPromises = [];

    for (let i = 0; i < measures.length; i += 1) {
      indicatorsPromises = [
        ...indicatorsPromises,
        quantumApi.fetchIndicator(
          assetType,
          assetIdentifier,
          measures[i],
          period
        ),
      ];
    }

    const resolvedIndicators = await Promise.allSettled(indicatorsPromises);

    for (let i = 0; i < measures.length; i += 1) {
      if (resolvedIndicators[i].status === "fulfilled") {
        indicatorsProcessed[t[`measure:${measures[i]}`]] = {
          value:
            resolvedIndicators[i].value.data.value !== null
              ? formatValue(
                  resolvedIndicators[i].value.data.value,
                  resolvedIndicators[i].value.data.format
                )
              : "-",
          period: formatPeriod(
            resolvedIndicators[i].value.data.finalDate,
            resolvedIndicators[i].value.data.initialDate
          ),
        };
      }
    }

    return indicatorsProcessed;
  };

  const processIndicatorsDrawdown = async (
    assetType,
    assetIdentifier,
    period
  ) => {
    const measures = [
      "DRAW_DOWN",
      "INICIO_DO_1_DRAW_DOWN",
      "DATA_DO_1_DRAW_DOWN",
      "PRIMEIRO_DRAW_DOWN",
    ];
    let indicatorsProcessed = {};
    let indicatorsPromises = [];

    for (let i = 0; i < measures.length; i += 1) {
      indicatorsPromises = [
        ...indicatorsPromises,
        quantumApi.fetchIndicator(
          assetType,
          assetIdentifier,
          measures[i],
          period
        ),
      ];
    }

    const resolvedIndicators = await Promise.allSettled(indicatorsPromises);

    for (let i = 0; i < measures.length; i += 1) {
      if (resolvedIndicators[i].status === "fulfilled") {
        indicatorsProcessed[t[`measure:${measures[i]}`]] = {
          value:
            resolvedIndicators[i].value.data.value !== null
              ? formatValue(
                  resolvedIndicators[i].value.data.value,
                  resolvedIndicators[i].value.data.format
                )
              : "-",
          period: formatPeriod(
            resolvedIndicators[i].value.data.finalDate,
            resolvedIndicators[i].value.data.initialDate
          ),
        };
      }
    }

    return indicatorsProcessed;
  };

  const processReturnHistoryData = (data) => {
    let processedReturnHistoryData = {};
    data.reverse().forEach((value) => {
      const columnName = `${shortMonthNameForDate(
        value.finalDate
      )}. ${yearForDate(value.finalDate)}`;
      processedReturnHistoryData[columnName] = Number(
        Number(value.value).toFixed(2)
      );
    });
    return processedReturnHistoryData;
  };

  const processRentabilitySeries = async (
    assetType,
    identifier,
    periodRange,
    initialApplication
  ) => {
    const { data } = await quantumApi.fetchRentabilitySeries(
      assetType,
      identifier,
      periodRange,
      initialApplication
    );

    return processSeries(data);
  };

  const processSeries = (data, transformToPercent) => {
    return data.map((serieItem) => {
      const dateTimestamp = new Date(serieItem.date).valueOf();
      const value = transformToPercent
        ? Number(Number(serieItem.value * 100).toFixed(2))
        : Number(Number(serieItem.value).toFixed(2));

      return [dateTimestamp, value];
    });
  };

  const findAssetIndexByIdentifierAndType = (assetIdentifier, assetType) => {
    let assetFoundIndex = -1;
    for (let i = 0; i < assets.length; i += 1) {
      if (
        assets[i].identifier === assetIdentifier &&
        assets[i].assetType === assetType
      ) {
        assetFoundIndex = i;
        break;
      }
    }
    return assetFoundIndex;
  };

  const changeAssetColor = (color, assetIdentifier, assetType) => {
    const assetFoundIndex = findAssetIndexByIdentifierAndType(
      assetIdentifier,
      assetType
    );

    if (assetFoundIndex !== -1) {
      let clonedAssets = [...assets];
      clonedAssets[assetFoundIndex].color = color;
      setAssets(clonedAssets);
    }
  };

  const removeAsset = async (assetIdentifier, assetType) => {
    const assetFoundIndex = findAssetIndexByIdentifierAndType(
      assetIdentifier,
      assetType
    );

    if (assetFoundIndex !== -1) {
      const benchmarkIndex = findBenchmarkAsset(assetIdentifier, assetType);

      if (benchmarkIndex !== -1) {
        if (selectedBenchmarksIndexes.length === 1) return;
        setSelectedBenchmarksIndexes(
          selectedBenchmarksIndexes.filter(
            (selectedBenchmarkIndex) =>
              selectedBenchmarkIndex !== benchmarkIndex
          )
        );
      }

      const newAssets = [...assets];
      newAssets.splice(assetFoundIndex, 1);
      setAssets(newAssets);

      let period = selectedPeriod;
      let mustRecalculateAssetsData = false;

      if (!userHasInteractedWithPeriod) {
        const { period: defaultPeriod } = await quantumApi.fetchDefaultPeriod(
          newAssets
        );

        if (selectedPeriod !== defaultPeriod) {
          mustRecalculateAssetsData = true;

          period = defaultPeriod;
          setSelectedPeriodLockingUpdate(period);
        }
      }

      // Caso seja o período ótimo, precisamos atualizar a série dos ativos
      if (period === PERIOD_OPTIMAL) {
        try {
          setFetchingAssetsSeries(true);

          const [periodRange, assetsWithoutSeries] = await fetchPeriodRange(
            period,
            newAssets
          );
          setAssetsWithoutSeries(assetsWithoutSeries);

          await updateAssetsData(newAssets, periodRange, initialApplication);
        } catch (err) {
          console.log("[Erro ao atualizar série]", err);
        } finally {
          setFetchingAssetsSeries(false);
        }
      } else if (baseDate !== lastUpdateDate || mustRecalculateAssetsData) {
        await updateAssetsDataForPeriodChange(period, newAssets);
      }
    }
  };

  const removeAllAssets = () => {
    setAssets([]);
    setCorrelationData({});
    setSelectedBenchmarksIndexes([]);
  };

  const removeAllAssetsExceptBenchmarks = async () => {
    const benchmarks = assets.filter(({ assetType }) => assetType === "INDICE");
    setAssets(benchmarks);

    // Caso seja o período ótimo, precisamos atualizar a série dos ativos
    if (selectedPeriod === PERIOD_OPTIMAL) {
      try {
        setFetchingAssetsSeries(true);

        const [periodRange, assetsWithoutSeries] = await fetchPeriodRange(
          selectedPeriod,
          benchmarks
        );
        setAssetsWithoutSeries(assetsWithoutSeries);

        await updateAssetsData(benchmarks, periodRange, initialApplication);
      } catch (err) {
        console.log("[Erro ao atualizar série]", err);
      } finally {
        setFetchingAssetsSeries(false);
      }
    } else if (baseDate !== lastUpdateDate) {
      await updateAssetsDataForPeriodChange(selectedPeriod, benchmarks);
    }
  };

  useEffect(() => {
    const fetchOnLoad = async () => {
      const [benchmarks, lastUpdateDate] = await Promise.all([
        quantumApi.fetchBenchmarksAssets(),
        quantumApi.fetchLastUpdateDate(),
      ]);

      setBenchmarkAssets(benchmarks.data);
      setLastUpdateDate(lastUpdateDate);
    };

    fetchOnLoad();
  }, []);

  return (
    <AssetsContext.Provider
      value={{
        assets,
        setAssets,
        addAsset,
        changeAssetColor,
        getAssetColor,
        removeAsset,
        updateWhenChangesInitialApplication,
        updateWhenChangesPeriod,
        benchmarkAssets,
        selectedBenchmarksIndexes,
        setSelectedBenchmarksIndexes,
        findBenchmarkAsset,
        isLoadingAssets: isLoading,
        addAssetsBatch,
        setIsLoadingAssets: setIsLoading,
        removeAllAssets,
        removeAllAssetsExceptBenchmarks,
      }}
    >
      {children}
    </AssetsContext.Provider>
  );
};

export default AssetsProvider;
