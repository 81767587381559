import React, { useState, useEffect, useMemo } from "react";

import {
  GraphicTemplate,
  SearchHeader,
  TitleHeader,
  ConfigurationHeader,
  NavigationHeader,
  FooterGraphicPage,
  SwiperPagination,
  SerieModal,
  AcordionTableIndexPeriod,
  AcordionTableReturnHistory,
  LoadingMask,
  ShareWhatsAppButton,
} from "components";
import { SAppBar, SToolbar, STypography } from "./styles";
import HigchartsBase from "components/Highcharts/base";
import HighchartsCorrelation from "components/Highcharts/correlation";
import { useMyContext, useAssets } from "hooks";
import { ReduxStatePane } from "../../organisms/ReduxStatePane";
import {
  useSelectPeriod,
  useSelectInicialApplication,
  useSelectTab,
  useSelectBenchmarksIndexes,
} from "../../../state";
import { useParams } from "react-router-dom";
import { urlParser } from "../../../utils/urlParser";
import { INITIAL_BENCHMARK_INDEX } from "models/constants";
import LinkErrorModal from "components/molecules/Modals/LinkErrorModal";
import LinkLoadingModal from "components/molecules/Modals/LinkLoadingModal";
import { trackEvent } from "../../../analytics";
import { useWindowSize } from "react-use";

const GraphicPage = () => {
  const {
    assets,
    updateWhenChangesInitialApplication,
    updateWhenChangesPeriod,
    changeAssetColor,
    removeAsset,
    isLoadingAssets,
    addAssetsBatch,
    benchmarkAssets,
  } = useAssets();

  const { urlData } = useParams();
  const configFromUrl = urlData && urlParser(urlData);

  const [selectedPeriod, setSelectedPeriod] = useSelectPeriod();
  const [isLoading, setIsLoading] = useState(true);
  const { t, history } = useMyContext();
  const { width } = useWindowSize();
  const [initialAssets, setInitialAssets] = useState(
    history.location.state && history.location.state.selectedAssets
      ? [...history.location.state.selectedAssets]
      : []
  );

  const [indicatorsRentabilityKeys, setIndicatorsRentabilityKeys] = useState(
    []
  );
  const [indicatorsReturnKeys, setIndicatorsReturnKeys] = useState([]);
  const [indicatorsDrawdownKeys, setIndicatorsDrawdownKeys] = useState([]);

  const [initialApplication, setInitialApplication] =
    useSelectInicialApplication();
  const [selectedTab, setSelectedTab] = useSelectTab();

  const [selectedBenchmarksIndexes, setSelectedBenchmarksIndexes] =
    useSelectBenchmarksIndexes();

  const [linkErrorModal, setLinkErrorModal] = useState(false);
  const [linkLoading, setLinkLoading] = useState(false);

  const tabTitle = useMemo(() => {
    switch (selectedTab) {
      // case 0:
      //   return t["tabs:Rentability"];
      case 1:
        return t["tabs:Return"];
      case 2:
        return t["tabs:Underwater"];
      // case 3:
      //   return t["tabs:Correlation"];
      default:
        return null;
    }
  }, [t, selectedTab]);

  useEffect(() => {
    if (configFromUrl !== null) {
      trackEvent({
        name: "carregou_compartilhamento",
        properties: {
          link: urlData,
        },
      });
      configFromUrl && setInitialApplication(configFromUrl.initialAmount);
      configFromUrl && setSelectedPeriod(configFromUrl.period);
      configFromUrl && setSelectedTab(Number(configFromUrl.tab));
      configFromUrl &&
        setSelectedBenchmarksIndexes([
          ...configFromUrl.benchmarks.map((bench) => Number(bench)),
        ]);
    }
  }, []);

  useEffect(() => {
    if (assets.length > 0) {
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    const updateInitialAssetsEffect = async () => {
      if (benchmarkAssets.length > 0) {
        const isShareLink = !(
          configFromUrl === undefined || configFromUrl === null
        );
        setLinkLoading(isShareLink);

        setIsLoading(true);

        const alreadyHasBenchmarks =
          initialAssets.filter(({ assetType }) => assetType === "INDICE")
            .length > 0;

        const initial = !isShareLink
          ? alreadyHasBenchmarks
            ? initialAssets
            : [
                ...selectedBenchmarksIndexes.map(
                  (x) => benchmarkAssets[Number(x)]
                ),
                ...initialAssets,
              ] // CUSTOMIZATION: FORCE USAGE OF DEFAULT ON STATE BENCHMARKS
          : [
              ...selectedBenchmarksIndexes.map(
                (x) => benchmarkAssets[Number(x)]
              ),
              ...initialAssets,
              ...configFromUrl.assets,
            ];

        await addAssetsBatch(
          initial,
          selectedPeriod,
          initialApplication,
          // Manter período, mesmo com ativos sem séries
          !isShareLink
        );
        configFromUrl === null && setLinkErrorModal(true);
        setIsLoading(false);
        setLinkLoading(false);
      }
    };
    updateInitialAssetsEffect();
  }, [initialAssets, benchmarkAssets]);

  useEffect(() => {
    const updatePeriodEffect = async () => {
      await updateWhenChangesPeriod(selectedPeriod);
    };
    updatePeriodEffect();
  }, [selectedPeriod]);

  useEffect(() => {
    const updateinitialApplicationEffect = async () => {
      await updateWhenChangesInitialApplication(initialApplication);
    };
    updateinitialApplicationEffect();
  }, [initialApplication]);

  useEffect(() => {
    const updateAssetsEffect = async () => {
      if (assets.length > 0) {
        if (assets[0]?.indicatorsRentabilityData)
          setIndicatorsRentabilityKeys(
            Object.keys(assets[0].indicatorsRentabilityData)
          );
        if (assets[0]?.indicatorsReturnData)
          setIndicatorsReturnKeys(Object.keys(assets[0].indicatorsReturnData));
        if (assets[0]?.indicatorsDrawdownData)
          setIndicatorsDrawdownKeys(
            Object.keys(assets[0].indicatorsDrawdownData)
          );
      }
    };
    updateAssetsEffect();
  }, [assets]);

  const getIndicatorsColumns = () => {
    switch (selectedTab) {
      case 0:
        return indicatorsRentabilityKeys;
      case 1:
        return indicatorsReturnKeys;
      case 2:
        return indicatorsDrawdownKeys;
      default:
        return null;
    }
  };

  const getTypeSwiper = () => {
    switch (selectedTab) {
      case 0:
        return "indicatorsRentability";
      case 1:
        return "indicatorsReturn";
      case 2:
        return "indicatorsDrawdown";
      default:
        return null;
    }
  };

  return (
    <>
      <GraphicTemplate
        header={
          <TitleHeader
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        }
        secondHeader={
          <SearchHeader
            period={selectedPeriod}
            initialApplication={initialApplication}
          />
        }
        /* CUSTOMIZATION: NO CONTROLS FOR THIS CLIENT */
        /*   thirdHeader={ */
        /*   <ConfigurationHeader */
        /*     setSelectedPeriod={setSelectedPeriod} */
        /*     selectedPeriod={selectedPeriod} */
        /*     initialApplication={initialApplication} */
        /*     setInitialApplication={setInitialApplication} */
        /*     selectedTab={selectedTab} */
        /*   /> */
        /* } */
        fourthHeader={<NavigationHeader />}
        footer={<FooterGraphicPage />}
      >
        <>
          {linkLoading && <LinkLoadingModal />}
          {(isLoadingAssets || isLoading) && <LoadingMask />}
          {width > 0 && (
            <SAppBar position="static" sx={{ backgroundColor: "#28285C" }}>
              <SToolbar>
                <STypography>{tabTitle}</STypography>
              </SToolbar>
            </SAppBar>
          )}
          {selectedTab !== 3 ? (
            <>
              <HigchartsBase assets={assets} selectedTab={selectedTab} />
              {width <= 0 ? (
                <>
                  <SwiperPagination
                    assetsData={assets}
                    type={getTypeSwiper()}
                  />
                  {getTypeSwiper() !== "indicatorsDrawdown" && (
                    <SwiperPagination assetsData={assets} type="history" />
                  )}
                </>
              ) : (
                <>
                  <AcordionTableIndexPeriod
                    title={t["table:Header:Indicators"]}
                    assetsData={assets}
                    columns={getIndicatorsColumns()}
                    changeAssetColor={changeAssetColor}
                    removeAsset={removeAsset}
                    selectedTab={selectedTab}
                  />
                  {selectedTab !== 2 && (
                    <AcordionTableReturnHistory
                      title={t["table:Header:ReturnHistory"]}
                      assetsData={assets}
                      changeAssetColor={changeAssetColor}
                      removeAsset={removeAsset}
                    />
                  )}
                </>
              )}
            </>
          ) : (
            <HighchartsCorrelation
              assets={assets}
              selectedTab={selectedTab}
              selectedPeriod={selectedPeriod}
              selectedBenchmarksIndexes={selectedBenchmarksIndexes}
            />
          )}
          <ShareWhatsAppButton />
        </>
        <SerieModal />

        <ReduxStatePane />
      </GraphicTemplate>
      <LinkErrorModal
        isOpen={linkErrorModal}
        body={t["share:invalidLink"]}
        onClose={() => setLinkErrorModal(false)}
      />
    </>
  );
};

export default GraphicPage;
