import styled from "styled-components";
import { Div } from "components";

export const Wrapper = styled(Div)`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000000;
  overflow: hidden;
  align-items: center;
  justify-content: center;
`;
