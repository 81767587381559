import React from "react";
import ReactGA from "react-ga4";
import { hotjar } from "react-hotjar";

import Routes from "./Routes";
import { initialize } from "../analytics";

import {
  GOOGLE_ANALYTICS_ID,
  HOTJAR_ID,
  HOTJAR_SNIPPET_VERSION,
  APPLICATION_INSIGHTS_CONNECTION_STRING,
  API_SUBSCRIPTION_KEY,
} from "environment";

if (GOOGLE_ANALYTICS_ID) {
  ReactGA.initialize(GOOGLE_ANALYTICS_ID);
}

if (HOTJAR_ID && HOTJAR_SNIPPET_VERSION) {
  hotjar.initialize(HOTJAR_ID, HOTJAR_SNIPPET_VERSION);
}

if (APPLICATION_INSIGHTS_CONNECTION_STRING) {
  initialize(APPLICATION_INSIGHTS_CONNECTION_STRING, API_SUBSCRIPTION_KEY);
}

const App = () => {
  return <Routes id="App" />;
};

export default App;
