import React, { useState, useCallback, useMemo } from "react";
import useMyContext from "../../../hooks/useMyContext";

// import { AgGridColumn } from "ag-grid-react";
// import { Acordion, ActiveBox } from "components";
// import { StyledAgGrid } from "../AcordionTable/styles";
// import {
//   AssetColumHeader,
//   CustomHeader,
//   lastRowBorder,
//   getRowNodeId,
// } from "../AcordionTable/index";
// import OverlayRow from "../OverlayRow";
// import "ag-grid-community/dist/styles/ag-grid.css";
// import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import Utils from "utils/utils";
import { CATEGORY_COLORS } from "../../../models/constants";
import { pathOr } from "ramda";

import {
  Wrapper,
  STable,
  STableHead,
  STableBody,
  STableRow,
  SHeaderCell,
  SCell,
  SBar,
  LeftContent,
  RightContent,
  SDeleteIcon,
  STypography,
  AssetLabel,
} from "./styles";

const AcordionTableReturnHistory = ({
  title,
  assetsData,
  changeAssetColor,
  removeAsset,
}) => {
  const { t } = useMyContext();
  const { formatPercent } = Utils();
  const [assetBeingHovered, setAssetBeingHovered] = useState(null);

  const columns = useMemo(() => {
    if (assetsData.length > 0) {
      return Object.keys(assetsData[0].returnHistoryData);
    }
  }, [assetsData]);

  const benchmarkNumber = useMemo(() => {
    if (assetsData.length > 0) {
      return assetsData.filter((asset) => asset?.assetType === "INDICE").length;
    }
  }, [assetsData]);

  const onHover = (assetIdentifier) => setAssetBeingHovered(assetIdentifier);

  return (
    assetsData.length > 0 && (
      <Wrapper>
        <STable>
          <colgroup>
            <col style={{ width: "35%" }} />
          </colgroup>
          <STableHead>
            <STableRow
              key="header-rentability"
              sx={{ backgroundColor: "#F1F3F5" }}
            >
              <SHeaderCell>{t["common:asset"]}</SHeaderCell>
              {columns.map((column) => (
                <SHeaderCell align="right" key={column}>
                  {column.toString().replace(".", "")}
                </SHeaderCell>
              ))}
            </STableRow>
          </STableHead>
          <STableBody>
            {assetsData.map((asset, index) => (
              <STableRow
                key={asset.identifier}
                sx={{
                  backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#F1F3F5",
                }}
                onMouseEnter={() => onHover(asset.identifier)}
                onMouseLeave={() => onHover(null)}
              >
                <SCell>
                  <LeftContent
                    style={{
                      opacity:
                        assetBeingHovered === asset?.identifier ? 0.5 : 1,
                    }}
                  >
                    <SBar barColor={asset.color} />
                    <AssetLabel>{asset.label}</AssetLabel>
                  </LeftContent>
                  {(asset?.assetType === "INDICE"
                    ? false // CUSTOMIZATION: NO BENCHMARK IS ALLOWED TO BE DELETED SINCE WE HAVE NO CONTROLS
                    : assetBeingHovered === asset?.identifier) && (
                    <RightContent
                      onClick={() =>
                        removeAsset(asset.identifier, asset.assetType)
                      }
                    >
                      <SDeleteIcon />
                      <STypography>{t["common:remove"]}</STypography>
                    </RightContent>
                  )}
                </SCell>
                {Object.keys(pathOr([], ["returnHistoryData"])(asset)).map(
                  (key) => (
                    <SCell
                      align="right"
                      key={asset?.identifier + key}
                      sx={{
                        opacity:
                          assetBeingHovered === asset?.identifier ? 0.5 : 1,
                      }}
                    >
                      {formatPercent(
                        pathOr("-", ["returnHistoryData", key])(asset)
                      )}
                    </SCell>
                  )
                )}
              </STableRow>
            ))}
          </STableBody>
        </STable>
      </Wrapper>
    )
  );
};

export default AcordionTableReturnHistory;

//   <Acordion title={title}>
//     {assetsData.length > 0 && (
//       <div
//         className="ag-theme-alpine"
//         style={{ width: "100%", border: "none" }}
//       >
//         <StyledAgGrid
//           rowData={assetsData}
//           frameworkComponents={{
//             activeBox: ActiveBox,
//             overlayRow: OverlayRow,
//             assetColumnHeader: AssetColumHeader,
//             customHeader: CustomHeader,
//           }}
//           domLayout={"autoHeight"}
//           defaultColDef={{
//             flex: 1,
//             sortable: true,
//           }}
//           getRowNodeId={getRowNodeId}
//           getRowStyle={lastRowBorder}
//         >
//           <AgGridColumn
//             pinned="left"
//             lockPinned={true}
//             lockPosition={true}
//             cellClass="lock-pinned"
//             cellStyle={{ padding: 0, alignSelf: "center" }}
//             headerName="Ativo"
//             cellRenderer="activeBox"
//             headerComponent="assetColumnHeader"
//           />
//           {keys.map((key, index) => (
//             <AgGridColumn
//               key={key}
//               cellStyle={{
//                 marginBottom: "none",
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//                 borderRight:
//                   keys.length === index + 1
//                     ? "none"
//                     : "1px solid rgba(51, 51, 51, 0.4)",
//               }}
//               field={key}
//               lockPosition={true}
//               width={81}
//               minWidth={81}
//               headerClass={
//                 keys.length === index + 1 ? "last-column-header" : ""
//               }
//               valueFormatter={fomartValue}
//               headerComponent="customHeader"
//             />
//           ))}
//           <AgGridColumn
//             pinned="right"
//             lockPinned={true}
//             cellStyle={{ padding: 0, alignSelf: "center" }}
//             headerName="Delete Button"
//             cellRenderer="overlayRow"
//             cellRendererParams={{ changeAssetColor, removeAsset }}
//           />
//         </StyledAgGrid>
//       </div>
//     )}
//   </Acordion>
