import React, { useState } from "react";
import { useMyContext } from "hooks";
import {
  Wrapper,
  NavRight,
  NavLeft,
  WrapperGrid,
  Title,
  Message,
  FirstRow,
  SecondRow,
  STypography,
  SContainer,
  QRCodeIcon,
  CopyContentIcon,
} from "./styles";
import {
  useSelectAssets,
  useSelectBenchmarksIndexes,
  useSelectInicialApplication,
  useSelectPeriod,
  useSelectSharedLink,
  useSelectTab,
} from "state";
import { generateSharedLink, getCurrentUrlBase } from "utils/urlParser";
import SharedLinkModal from "../Modals/SharedLinkModal";
import QRCodeModal from "../Modals/QRCodeModal";
import CopyLinkModal from "../Modals/CopyLinkModal";
import { trackEvent } from "../../../analytics";
import { useWindowSize } from "react-use";

const TitleHeader = ({ selectedTab, setSelectedTab }) => {
  const { t } = useMyContext();
  const [linkModalOpen, setLinkModalOpen] = useState(false);
  const [copyModalOpen, setCopyModalOpen] = useState(false);
  const [qrCodeModalOpen, setQRCodeModalOpen] = useState(false);
  const { width } = useWindowSize();
  const [sharedLink, setSharedLink] = useSelectSharedLink();
  const [initialApplication] = useSelectInicialApplication();
  const [selectedTabState] = useSelectTab();
  const [selectedBenchmarksIndexes] = useSelectBenchmarksIndexes();
  const [selectedPeriod] = useSelectPeriod();

  const [assets] = useSelectAssets();

  const handleShareClick = async () => {
    const sharedLinkURL = generateSharedLink(
      initialApplication,
      selectedTabState,
      selectedBenchmarksIndexes,
      selectedPeriod,
      assets
    );
    if (sharedLinkURL !== null) {
      trackEvent({
        name: "compartilhou_link",
        properties: {
          link: sharedLinkURL,
          ativos: assets,
          periodo: selectedPeriod,
        },
      });
      setSharedLink(sharedLinkURL);
      setCopyModalOpen(true);
      // setLinkModalOpen(true);
    }
  };

  const handleQRCodeClick = async () => {
    const sharedLinkURL = generateSharedLink(
      initialApplication,
      selectedTabState,
      selectedBenchmarksIndexes,
      selectedPeriod,
      assets
    );
    if (sharedLinkURL !== null) {
      trackEvent({
        name: "compartilhou_qrcode",
        properties: {
          link: sharedLinkURL,
          ativos: assets,
          periodo: selectedPeriod,
        },
      });
      setSharedLink(sharedLinkURL);
      setQRCodeModalOpen(true);
    }
  };

  const clientURL = getCurrentUrlBase();

  return (
    <Wrapper>
      <CopyLinkModal
        isOpen={copyModalOpen}
        link={clientURL + sharedLink}
        onClose={() => setCopyModalOpen(false)}
      />
      <SharedLinkModal
        isOpen={linkModalOpen}
        link={clientURL + sharedLink}
        onClose={() => setLinkModalOpen(false)}
      />
      <QRCodeModal
        isOpen={qrCodeModalOpen}
        link={clientURL + sharedLink}
        onClose={() => setQRCodeModalOpen(false)}
      />

      {width > 0 ? (
        <WrapperGrid>
          <FirstRow>
            <NavLeft>
              <Title>Compare os ativos</Title>
            </NavLeft>
            <NavRight style={{ border: "none" }}>
              {/* CUSTOMIZATION: NO SHARING FOR THIS CLIENT */}
              {/* <SContainer index={5} onClick={handleQRCodeClick}> */}
              {/*   <QRCodeIcon /> */}
              {/*   <STypography>{t["share:QRCode"]}</STypography> */}
              {/* </SContainer> */}
              {/* <SContainer index={4} onClick={handleShareClick}> */}
              {/*   <CopyContentIcon /> */}
              {/*   <STypography>{t["share:copyLink"]}</STypography> */}
              {/* </SContainer> */}

              {/* DISABLED FEATURE: BASE DATE */}
              {/* <SContainer index={6}>
                  <BaseDateCalendarIcon />
                  <SBold>{t["period:baseDate"]}</SBold>
                  <STypography sx={{ fontSize: "15px !important" }}>
                    {formatDateFullYear(baseDate)}
                  </STypography>
                </SContainer> */}
            </NavRight>
          </FirstRow>

          <SecondRow>
            <Message>
              Compare o retorno, a volatilidade e outros indicadores de ativos.
            </Message>
          </SecondRow>
        </WrapperGrid>
      ) : null}
    </Wrapper>
  );
};

export default TitleHeader;
