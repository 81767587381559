import React, { useCallback, useState, useMemo } from "react";
// import { AgGridColumn } from "ag-grid-react";
// import { Acordion, ActiveBox } from "components";
// import { StyledAgGrid } from "../AcordionTable/styles";
// import {
//   AssetColumHeader,
//   CustomHeader,
//   getRowNodeId,
//   lastRowBorder,
// } from "../AcordionTable/index";
// import { OverlayRow } from "components";
// import "ag-grid-community/dist/styles/ag-grid.css";
// import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import { COLUMN_WIDTHS } from "./../../../models/constants";
import useMyContext from "../../../hooks/useMyContext";
import { propOr } from "ramda";

import {
  Wrapper,
  STable,
  STableHead,
  STableBody,
  STableRow,
  SHeaderCell,
  SCell,
  SBar,
  LeftContent,
  RightContent,
  SDeleteIcon,
  STypography,
  AssetLabel,
  AlignRight,
} from "./styles";

const dateRegexp = /^\d{2}\/\d{2}\/\d{2,}$/;

const AcordionTableIndicators = ({
  title,
  assetsData: assetsOriginalData,
  changeAssetColor,
  removeAsset,
  selectedTab,
  columns: columnsOriginalData,
}) => {
  const { t } = useMyContext();
  const [assetBeingHovered, setAssetBeingHovered] = useState(null);

  const assetsData = assetsOriginalData.map((assetsData) => ({
    ...assetsData,
    indicatorsReturnData: {
      Retorno: assetsData.indicatorsReturnData.Retorno,
      Volatilidade: assetsData.indicatorsRentabilityData.Volatilidade,
      Sharpe: assetsData.indicatorsRentabilityData.Sharpe,
      "PL Milhões (6 meses)": assetsData.indicatorsRentabilityData[
        "PL Milhões 6"
      ] || {
        value: "-",
      },
      "PL Milhões (Atual)": assetsData.indicatorsRentabilityData["PL Milhões"],
    },
  }));

  const columns =
    selectedTab === 1
      ? [
          "Retorno",
          "Volatilidade",
          "Sharpe",
          "PL Milhões (6 meses)",
          "PL Milhões (Atual)",
        ]
      : columnsOriginalData;

  const benchmarkNumber = useMemo(() => {
    if (assetsData.length > 0) {
      return assetsData.filter((asset) => asset?.assetType === "INDICE").length;
    }
  }, [assetsData]);

  const getDataOr = useCallback(
    (or, asset) => {
      switch (selectedTab) {
        case 0:
          return propOr(or, "indicatorsRentabilityData", asset);
        case 1:
          return propOr(or, "indicatorsReturnData", asset);
        case 2:
          return propOr(or, "indicatorsDrawdownData", asset);
        default:
          return null;
      }
    },
    [selectedTab]
  );

  const onHover = (assetIdentifier) => setAssetBeingHovered(assetIdentifier);

  return (
    <Wrapper>
      <STable sx={{ tableLayout: selectedTab === 2 ? "auto" : "fixed" }}>
        <colgroup>
          <col style={{ width: "35%" }} />
        </colgroup>
        <STableHead>
          <STableRow
            key="header-rentability"
            sx={{ backgroundColor: "#F1F3F5" }}
          >
            <SHeaderCell>{t["common:asset"]}</SHeaderCell>
            {columns.map((columnName) => (
              <SHeaderCell align="center" key={columnName}>
                <AlignRight sx={{ width: COLUMN_WIDTHS[columnName] }}>
                  {columnName}
                </AlignRight>
              </SHeaderCell>
            ))}
          </STableRow>
        </STableHead>
        <STableBody>
          {assetsData.map((asset, index) => (
            <STableRow
              key={asset.identifier}
              sx={{
                backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#F1F3F5",
              }}
              onMouseEnter={() => onHover(asset.identifier)}
              onMouseLeave={() => onHover(null)}
            >
              <SCell>
                <LeftContent
                  style={{
                    opacity: assetBeingHovered === asset?.identifier ? 0.5 : 1,
                  }}
                >
                  <SBar barColor={asset.color} />
                  <AssetLabel>{asset.label}</AssetLabel>
                </LeftContent>
                {(asset?.assetType === "INDICE"
                  ? false // CUSTOMIZATION: NO BENCHMARK IS ALLOWED TO BE DELETED SINCE WE HAVE NO CONTROLS
                  : assetBeingHovered === asset?.identifier) && (
                  <RightContent
                    onClick={() =>
                      removeAsset(asset.identifier, asset.assetType)
                    }
                  >
                    <SDeleteIcon />
                    <STypography>{t["common:remove"]}</STypography>
                  </RightContent>
                )}
              </SCell>
              {Object.keys(getDataOr([], asset)).map((columnName) => {
                const cellData = String(
                  getDataOr({}, asset)?.[columnName].value
                );
                return (
                  <SCell
                    key={asset?.identifier + columnName}
                    align="center"
                    sx={{
                      opacity:
                        assetBeingHovered === asset?.identifier ? 0.5 : 1,
                    }}
                  >
                    {cellData === "-" || cellData.match(dateRegexp) ? (
                      cellData
                    ) : (
                      <AlignRight
                        sx={{
                          width: `${COLUMN_WIDTHS[columnName]}px !important`,
                        }}
                      >
                        {cellData.includes("-") ? (
                          <>
                            <span>{cellData.replace("-", "")}</span>
                            <span>-</span>
                          </>
                        ) : (
                          cellData
                        )}
                      </AlignRight>
                    )}
                  </SCell>
                );
              })}
            </STableRow>
          ))}
        </STableBody>
      </STable>
    </Wrapper>
  );
};

export default AcordionTableIndicators;

// <Acordion title={title}>
//   {assetsData.length > 0 && (
//     <div className="ag-theme-alpine" style={{ width: "100%" }}>
//       <StyledAgGrid
//         rowData={assetsData}
//         frameworkComponents={{
//           activeBox: ActiveBox,
//           overlayRow: OverlayRow,
//           customHeader: CustomHeader,
//           assetColumnHeader: AssetColumHeader,
//         }}
//         domLayout={"autoHeight"}
//         defaultColDef={{
//           flex: 1,
//           sortable: true,
//         }}
//         getRowNodeId={getRowNodeId}
//         getRowStyle={lastRowBorder}
//       >
//         <AgGridColumn
//           pinned="left"
//           lockPinned={true}
//           lockPosition={true}
//           cellClass="lock-pinned"
//           cellStyle={{ padding: 0, alignSelf: "center" }}
//           headerName="Ativo"
//           cellRenderer="activeBox"
//           headerComponent="assetColumnHeader"
//         />
//         {columns.map((column, index) => (
//           <AgGridColumn
//             key={column}
//             cellStyle={{
//               marginBottom: "none",
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "center",
//               borderRight:
//                 columns.length === index + 1
//                   ? "none"
//                   : "1px solid rgba(51, 51, 51, 0.4)",
//             }}
//             field={column}
//             lockPosition={true}
//             width={81}
//             minWidth={81}
//             headerClass={
//               columns.length === index + 1 ? "last-column-header" : ""
//             }
//             valueFormatter={(params) => valueFormatter(params, column)}
//             headerComponent="customHeader"
//             headerComponentParams={{
//               ...getPeriod(column),
//               customDisplayName: column,
//             }}
//           />
//         ))}
//         <AgGridColumn
//           pinned="right"
//           lockPinned={true}
//           cellStyle={{ padding: 0, alignSelf: "center" }}
//           headerName="Delete Button"
//           cellRenderer="overlayRow"
//           cellRendererParams={{ changeAssetColor, removeAsset }}
//         />
//       </StyledAgGrid>
//     </div>
//   )}
// </Acordion>
