import styled from "styled-components";
import CurrencyInput from "react-currency-input";
import { Text, Div } from "components";
import Item from "components/molecules/NavItem";

export const Wrapper = styled(Div)`
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  padding-top: 24px;

  flex-wrap: wrap;

  /* @media (max-width: 920px) {
    flex-wrap: wrap;
    padding: 0px;
  } */
`;

export const NavBox = styled(Div)`
  display: flex;
  flex-direction: column;

  &:not(:first-child) {
    padding-left: 15px;
  }

  /* @media (max-width: 920px) {
    padding: 0px 16px;
    margin-bottom: 0px;
    & + & {
      margin-top: 16px;
    }
  } */
`;

export const NavItem = styled(Item)`
  border: ${({ hasBorder, theme }) =>
    !!hasBorder &&
    `1px solid ${
      theme.colors[theme.background.tertiary.color][
        theme.background.tertiary.intensity
      ]
    }`};
  padding: ${({ hasBorder }) => !!hasBorder && "10px 14px"};
  border-radius: ${({ hasBorder }) => !!hasBorder && "2px"};
  font-size: ${({ theme }) => theme.fonts.size.md};
  font-weight: ${({ isSelected }) => (isSelected ? "600" : "normal")};
  height: 40px;
  white-space: nowrap;
  transition: background-color 0.2s ease-in-out;
  color: ${({ isSelected }) => (isSelected ? "#F7A526" : "#28285C")};

  & + & {
    margin-left: ${({ hasBorder }) => (!!hasBorder ? "12px" : "0px")};
  }

  &:hover {
    background-color: #28285c;
    opacity: 0.7;
    color: #f7a526;
  }

  /* @media (max-width: 920px) {
    border: ${({ hasBorder }) => !!hasBorder && `1px solid white`};
    padding: 10px;
  } */
`;

export const NavLeft = styled.ul`
  display: flex;
  list-style-type: none;
  border: 1px solid ${({ theme }) => theme.colors.gray[theme.borders[0]]};
`;

export const NavMiddle = styled.ul`
  display: flex;
  list-style-type: none;
  border: 1px solid ${({ theme }) => theme.colors.gray[theme.borders[0]]};
`;

export const NavRight = styled.ul`
  display: flex;
  list-style-type: none;
  border: 1px solid ${({ theme }) => theme.colors.gray[theme.borders[0]]};
`;

export const StyledText = styled(Text)`
  color: ${({ theme }) =>
    theme.colors[theme.textColors.primary.color][
      theme.textColors.primary.intensity
    ]};
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  font-weight: 700;
`;

export const StyledCurrencyInput = styled(CurrencyInput)`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 500;
  font-size: ${({ theme }) => theme.fonts.size.md};
  color: ${({ theme }) => theme.colors.gray[5]};
  padding: 12px 6px 12px 0px;
  text-align: end;
  border: 1px solid transparent;
  background-color: ${({ theme }) =>
    theme.colors[theme.background.quaternary.color][
      theme.background.quaternary.intensity
    ]};
  width: 128.5px;
  height: 40px;

  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.blue[theme.borders[1]]};
    border-radius: 2px;
  }

  &:focus-visible {
    outline: none;
  }
`;
