import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { omit } from "ramda";
import api from "services/api";
import { useMyContext } from "hooks";
import { SearchAssetItem, Text } from "components";
import { CATEGORY_COLORS } from "models/constants";

import {
  Wrapper,
  SearchList,
  StyledTextField,
  SearchListWrapper,
  CategorySeparatorLine,
} from "./styles";

const REGEX_TO_HIGHLIGHT = /\s*(?: |$)\s*/;

const SearchAssets = ({
  searchStringRef,
  style,
  initialApplication,
  period,
  addAsset,
}) => {
  const { t } = useMyContext();
  const [isOpenSearchList, setIsOpenSearchList] = useState(false);
  const [searchedItems, setSearchedItems] = useState({});
  const [wordsToHighlight, setWordsToHighlight] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchAssets = async (searchValue) => {
    try {
      const result = await api.get(`asset?searchString=${searchValue}`);
      return result.data;
    } catch (err) {
      console.log(err);
    }
  };

  const transformEuropaIntoFund = (searchResults) => {
    const searchResultsKeys =
      typeof searchResults === "object" ? Object.keys(searchResults) : [];

    if (
      searchResultsKeys.includes("EUROPA") &&
      searchResultsKeys.includes("FUNDO")
    ) {
      return {
        ...omit(["EUROPA"], searchResults),
        FUNDO: [...searchResults.FUNDO, ...searchResults.EUROPA],
      };
    }

    if (
      searchResultsKeys.includes("EUROPA") &&
      !searchResultsKeys.includes("FUNDO")
    ) {
      return {
        ...omit(["EUROPA"], searchedItems),
        FUNDO: [...searchResults.EUROPA],
      };
    }
    return searchResults;
  };

  const doSearch = async (searchValue) => {
    setIsLoading(true);
    const searchedAssets = await fetchAssets(searchValue);
    const categorizedAssets = transformEuropaIntoFund(searchedAssets);
    setIsLoading(false);

    const wordsToHighlightArr = searchValue
      ? searchValue.trim().split(REGEX_TO_HIGHLIGHT)
      : [];
    setWordsToHighlight(wordsToHighlightArr);

    setSearchedItems(categorizedAssets);
    setIsOpenSearchList(true);
  };

  const handleChange = async () => {
    const searchValue = searchStringRef.current.value;

    if (searchValue.length >= 3) {
      doSearch(searchValue);
    } else {
      setSearchedItems({});
      setIsOpenSearchList(false);
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleCancel = () => {
    setIsFocused(false);
    setIsOpenSearchList(false);
    setSearchedItems({});
    searchStringRef.current.value = "";
  };

  useEffect(() => {
    if (searchStringRef?.current) {
      searchStringRef.current.focus();
    }
  }, []);

  return (
    <Wrapper style={style}>
      <StyledTextField
        id="search-active"
        type="text"
        reference={searchStringRef}
        placeholder={t["organisms:SearchAssets:searchString"]}
        leftIconName="search"
        fullWidth
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        isFocused={isFocused}
        hasLoading={isLoading}
        onCancel={handleCancel}
      />
      <SearchListWrapper>
        {isOpenSearchList && (
          <SearchList>
            {Object.keys(searchedItems).length > 0 ? (
              Object.keys(searchedItems).map((assetCategory) => (
                <React.Fragment key={assetCategory}>
                  <CategorySeparatorLine
                    barColor={CATEGORY_COLORS[assetCategory]}
                  >
                    {t[`assetCategory:${assetCategory}`]}
                  </CategorySeparatorLine>
                  {searchedItems[assetCategory].map((asset) => (
                    <SearchAssetItem
                      key={asset.identifier + asset.assetType}
                      asset={asset}
                      handleOnMouseDown={() => {
                        addAsset(asset, period, initialApplication);
                        handleCancel();
                      }}
                      wordsToHighlight={wordsToHighlight}
                    />
                  ))}
                </React.Fragment>
              ))
            ) : (
              <Text
                style={{ marginTop: "10px", padding: "0 16px" }}
                textsize="lg"
                weight="600"
                color="blue"
              >
                {t["organisms:SearchAssets:nenhumResultadoEncontrado"]}
              </Text>
            )}
          </SearchList>
        )}
      </SearchListWrapper>
    </Wrapper>
  );
};

SearchAssets.propTypes = {
  searchStringRef: PropTypes.any.isRequired,
  addActive: PropTypes.func,
};

export default SearchAssets;
